import React from "react";
import { Redirect, Route } from "react-router-dom";

const RouteX = ({ component: Component, redirect, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("userInfo") ? (
        <Redirect to={redirect} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default RouteX;
