import { createContext, useState } from "react";

export const ErrorContext = createContext({
  message: "",
  setMessage: () => {},
  show: false,
  setShow: () => {},
});

const ErrorContextProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  return (
    <ErrorContext.Provider value={{ message, setMessage, show, setShow }}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
