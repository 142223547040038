import React, { useState, useEffect, useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { ErrorContext } from '../../../hoc/context/error-context';

const SnackBar = ({ show, message, severity, autoHideDuration = 4000 }) => {
  const [open, setOpen] = useState(false);
  const errorContext = useContext(ErrorContext);
  useEffect(() => {
    if (show) {
      setOpen(true);
    }
  }, [show]);
  const Alert = (props) => {
    return <MuiAlert elevation={3} variant="filled" {...props} />;
  };

  const handleClose = () => {
    setOpen(false);
    errorContext.setShow(false);
    errorContext.setMessage('');
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
    >
      <Alert onClose={handleClose} severity={severity || 'success'}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
