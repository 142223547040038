import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
// component
import NonActionSnackbar from '../../../../utilities/feedback-user/nonActionSnackBar';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API}`,
});

const CreateRoleForm = ({ setOpen, open, handleClose }) => {
  const theme = useTheme();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  // snackbar state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [serverity, setServerity] = useState('');
  //
  // for submit button
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    const req = {
      roleName: data.roleName.toLowerCase().trim(),
    };
    history.push('/roles');
    setOpenSnackbar(false);
    setIsLoading(true);

    axiosInstance
      .post('api/v1/role/store', req, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log(res.data);
        history.push({ pathname: '/roles', search: '?addRole=true' });
        setOpenSnackbar(true);
        setSnackbarMessage('Add new role successfully!');
        setServerity('success');
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        history.push({ pathname: '/roles', search: '?addRole=false' });
        setOpenSnackbar(true);
        setSnackbarMessage('Role Name already exists !');
        setServerity('error');
      });
  };

  return (
    <>
      <NonActionSnackbar
        show={openSnackbar}
        message={snackbarMessage}
        severity={serverity}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            style={{
              padding: '0.5rem 1.5rem',
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            }}
          >
            {'Create New Role'}
          </DialogTitle>
          <DialogContent style={{ padding: '1rem 1.5rem' }}>
            <Typography gutterBottom>Role Name</Typography>
            <TextField
              name="roleName"
              variant="outlined"
              size="small"
              placeholder={'Role\'s name'}
              fullWidth
              inputRef={register({
                required: true,
              })}
              error={errors.roleName ? true : false}
            />
          </DialogContent>
          <DialogActions style={{ padding: '1rem 1.5rem 1.5rem 1rem' }}>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              cancel
            </Button>
            <Button
              disableElevation
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={isLoading ? true : false}
            >
              {isLoading ? (
                <CircularProgress style={{ width: '24px', height: 'auto' }} />
              ) : (
                'create'
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateRoleForm;
