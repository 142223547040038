import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2B4BA9',
    },
    secondary: {
      main: '#E02241',
    },
  },
  typography: {
    fontFamily: ['Arimo', 'Siemreap', 'Roboto'],
    fontSize: 14,
  },
  overrides: {},
  shadows: [
    '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
    '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
    '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1.5px 10px 0 rgba(0, 0, 0, 0.1)',
  ],
});

export default theme;
