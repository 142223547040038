import React, { useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Expand from 'react-expand-animated';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ManageUIbyRole from '../../../../manage-ui-by-role';
import routesAndPermissions from '../../../../../utilities/dataForConfiguration/routes-and-permissions';
import { PermissionContext } from '../../../../../hoc/context/permission-context';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: '0',
  },
  active: {
    padding: '0',
    marginRight: '0',
    backgroundColor: '#203985',
    transition: 'all 0.2s ease-out',
  },
  dropdown: {
    padding: '0',
    backgroundColor: '#1f3e9b',
    fontStyle: 'normal',
  },
  dropdownActive: {
    padding: '0',
    backgroundColor: '#1b3994',
  },
}));

const MenuItem = ({
  title,
  href,
  dropdown,
  icon,
  marginBottom,
  active,
  setActive,
  open,
  conditions,
}) => {
  const classes = useStyles();
  // History Hook
  const history = useHistory();
  // Pathname
  const path = history.location.pathname;
  // Check Active Tab
  useEffect(() => {
    const path = history.location.pathname;
    if (href) {
      href === path && setActive();
    } else {
      dropdown.forEach((item) => {
        if (item.href.split('/')[1] === path.split('/')[1]) {
          setActive();
        }
      });
    }
  }, [history.location.pathname, dropdown, setActive, href]);
  // Create DropDown Here
  const dropDowns = dropdown?.map((item, index) => {
    const { title, href } = item;
    const route = href.split('/')[1];
    const active = path.split('/')[1] === route;
    const conditions = routesAndPermissions[`/${route}`];
    return (
      <ManageUIbyRole conditions={conditions} key={index}>
        <List
          className={clsx(classes.dropdown, {
            [classes.dropdownActive]: active,
          })}
        >
          <ListItem
            button
            disableRipple
            onClick={() => {
              if (href) {
                history.push(href);
              }
            }}
          >
            <ListItemText
              primary={title}
              style={{ paddingLeft: '25px' }}
              primaryTypographyProps={{
                style: { letterSpacing: '1px' },
              }}
            />
          </ListItem>
        </List>
      </ManageUIbyRole>
    );
  });

  // For Managing UI
  const { permission } = useContext(PermissionContext);

  return (
    <ManageUIbyRole conditions={conditions}>
      <List
        className={clsx(classes.list, { [classes.active]: active })}
        style={{
          marginBottom: marginBottom ? marginBottom : '0',
          zIndex: '10',
        }}
      >
        <ListItem
          button
          disableRipple
          onClick={() => {
            setActive();
            if (href) {
              history.push(href);
            } else {
              const featureNameOfFirstDropDownRoute =
                routesAndPermissions[dropdown[0].href]?.features[0]
                  ?.featureName;
              const featureNameOfSecondDropDownRoute =
                routesAndPermissions[dropdown[1].href]?.features[0]
                  ?.featureName;
              if (permission[featureNameOfFirstDropDownRoute].view) {
                history.push(dropdown[0].href);
              } else if (permission[featureNameOfSecondDropDownRoute].view) {
                history.push(dropdown[1].href);
              } else {
                history.push(dropdown[2].href);
              }
            }
          }}
        >
          <ListItemIcon style={{ paddingLeft: '5px', minWidth: '46px' }}>
            <img src={icon} alt="Icon" width="23px" height="23px" />
          </ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              style: { letterSpacing: '1px' },
            }}
          />
          {dropdown.length && active ? (
            <KeyboardArrowDownIcon />
          ) : dropdown.length ? (
            <KeyboardArrowRightIcon />
          ) : (
            ''
          )}
        </ListItem>
      </List>

      {/* DropDown Start Here */}

      {open ? (
        <Expand open={active} duration={250}>
          {dropDowns}
        </Expand>
      ) : (
        ''
      )}
    </ManageUIbyRole>
  );
};

export default React.memo(MenuItem);
