import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// components
import TitlesAndBtns from './titles-and-btns';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: '1rem',
    padding: '1rem 1.5rem',
    background: 'white',
    boxShadow: theme.shadows[0],
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Breadcrumb = ({ data }) => {
  const { title, parents } = data;
  const history = useHistory();
  const classes = useStyle();

  const parentsElement = parents?.map((item, index) => (
    <div
      key={index}
      onClick={() => {
        history.push(item.href);
      }}
      style={{ cursor: 'pointer' }}
    >
      {item.title}
    </div>
  ));

  return (
    <Box className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon />}
        style={{ fontSize: '1.2rem' }}
      >
        <div
          color="inherit"
          onClick={() => {
            history.push('/dashboard');
          }}
          style={{ cursor: 'pointer', display: 'flex' }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="tachometer-alt"
            className="svg-inline--fa fa-tachometer-alt fa-w-18"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            width="22"
            height="22"
            style={{ marginTop: '2px' }}
          >
            <path
              fill="grey"
              d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"
            ></path>
          </svg>
          <span
            style={{
              marginLeft: '5px',
            }}
          >
            Dashboard
          </span>
        </div>
        {parentsElement}
        <Typography color="primary">{title || ''}</Typography>
      </Breadcrumbs>

      <TitlesAndBtns title={title} />
    </Box>
  );
};

export default Breadcrumb;
