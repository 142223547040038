import { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import SingleNotification from './single-notification';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  withCredentials: true,
});

const useStyles = makeStyles((theme) => ({
  notificationContent: {
    position: 'absolute',
    transition: 'all 0.3s ease-out',
    top: '2.8rem',
    left: '-0.7rem',
    width: '370px',
    backgroundColor: 'white',
    padding: '0.6rem 0 0.6rem 0.6rem',
    zIndex: 10000,
    boxShadow:
      ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '5px',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  markAllAsRead: {
    margin: '0.3rem 0 0.7rem 0',
    cursor: 'pointer',
    padding: '0.2rem 0.5rem',
    transition: 'all 0.3s ease-out',
    color: theme.palette.primary.main,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#edf2ff',
    },
  },
}));

const AllNotifications = ({
  notification,
  showNotification,
  setShowNotification,
  fetchNotification,
  setPage,
  updateUnseenNotification,
}) => {
  // MUI Classes
  const classes = useStyles();
  // Handle notification element in state
  const [notificationElements, setNotificationElements] = useState(null);
  // Change appearance on frontend when click markallasread
  const [frontendMarkAllAsRead, setFrontendMarkAllAsRead] = useState({
    helper: 0,
    isRead: false,
  });

  // Use nofication props to generate element
  useEffect(() => {
    // Convert every notification data to Single Notification
    const notifications = notification.map((item, index) => {
      // Get Info for Each Notification
      const {
        customerName,
        workerName,
        invoiceNumber,
        action,
        orderId,
        isRead,
        isSeen,
        status,
        _id,
        createdAt,
        createdFrom,
      } = item;
      // Check whether we're at the last element of array for styling
      const last = index === notification.length - 1;

      return (
        <SingleNotification
          key={_id}
          last={last}
          index={index}
          customerName={customerName}
          workerName={workerName}
          invoiceNumber={invoiceNumber}
          action={action}
          orderId={orderId}
          isRead={isRead}
          isSeen={isSeen}
          status={status}
          id={_id}
          setShowNotification={setShowNotification}
          fetchNotification={fetchNotification}
          setPage={setPage}
          updateUnseenNotification={updateUnseenNotification}
          frontendIsReadFromParent={frontendMarkAllAsRead}
          date={createdAt}
          createdFrom={createdFrom}
        />
      );
    });
    setNotificationElements(notifications);
  }, [notification, frontendMarkAllAsRead]);

  // Mark All as Read Handler
  const markAllAsReadhandler = async () => {
    // Update all notification of the user to seen and read
    const { data } = await axiosInstance.post('api/v1/notification/update', {
      action: 'markAllAsRead',
    });
    // Update View
    setFrontendMarkAllAsRead({
      helper: frontendMarkAllAsRead.helper + 1,
      isRead: true,
    });
  };

  // Render View
  return (
    <div
      className={classes.notificationContent}
      style={{ display: showNotification ? 'block' : 'none' }}
    >
      <Grid container>
        <Grid item xs={true}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              color: 'rgb(49, 49, 49)',
              padding: '0.2rem 0.4rem',
            }}
          >
            Notifications
          </Typography>
        </Grid>
        <Grid item style={{ marginRight: '0.8rem' }}>
          {!!notification.length && (
            <Grid
              container
              className={classes.markAllAsRead}
              alignItems="center"
              justifyContent="flex-end"
              onClick={markAllAsReadhandler}
            >
              <CheckIcon style={{ marginRight: '0.3rem' }} /> Mark all as read
            </Grid>
          )}
        </Grid>
      </Grid>

      {notificationElements}
      {!notification.length && (
        <Grid container style={{ padding: '1rem' }} justifyContent="center">
          <Typography color="primary" variant="h6">
            No Notification Found !
          </Typography>
        </Grid>
      )}
    </div>
  );
};

export default AllNotifications;
