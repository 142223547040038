import React from 'react';
import clsx from 'clsx';
import { makeStyles, Drawer } from '@material-ui/core';

import Logo from '../../../assets/images/logo.jpg';
import GenerateMenuItems from '../drawer/generateMenuItems';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    position: 'relative',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 1120,
  },
  drawerOpen: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.only('xs')]: {
      position: 'fixed',
      top: '56px',
      left: '0',
      zIndex: 100,
    },
  },
  drawerClose: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
    [theme.breakpoints.only('xs')]: {
      position: 'fixed',
      top: '56px',
      left: '0',
    },
  },
  toolbar: ({ open }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    margin: open ? '35px 0' : '0',
    transition: 'all 0.3s ease-in-out',
  }),
}));

const SideDrawer = ({ open, role }) => {
  const classes = useStyles({ open });
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <img
          src={Logo}
          width={open ? '150px' : '50px'}
          alt="Logo"
          style={{ transition: 'all 0.3s ease-in-out' }}
        />
      </div>
      <GenerateMenuItems role={role} open={open} />
    </Drawer>
  );
};

export default SideDrawer;
