import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from 'react-router-dom';

import CreateRoleForm from '../../../pages/settings/role/create-role-form';
import ManageUiByRole from '../../../hoc/manage-ui-by-role';

const TitlesAndBtns = ({ title, btnProps }) => {
  // For Navigation
  const history = useHistory();
  // Create Role Section
  const [openCreateRoleDialog, setOpenCreateRoleDialog] = useState(false);
  const handleCloseCreateRoleDialog = () => {
    setOpenCreateRoleDialog(false);
  };

  const titlesAndBtns = {
    'Product List': (
      <ManageUiByRole feature="products" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => history.push('/create-product')}
        >
          Create New Product
        </Button>
      </ManageUiByRole>
    ),
    'Category List': (
      <ManageUiByRole feature="products" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => history.push('/create-category')}
        >
          Create New Category
        </Button>
      </ManageUiByRole>
    ),
    'Order List': (
      <ManageUiByRole feature="orders" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => history.push('/create-order')}
        >
          Create New Order
        </Button>
      </ManageUiByRole>
    ),
    'Customer List': (
      <ManageUiByRole feature="customer" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => history.push('/create-customer')}
        >
          Create New Customer
        </Button>
      </ManageUiByRole>
    ),
    'Supplier List': (
      <ManageUiByRole feature="supplier" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => history.push('/create-supplier')}
        >
          Create New Supplier
        </Button>
      </ManageUiByRole>
    ),
    'Sample Request List': (
      <ManageUiByRole feature="sample-request" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => history.push('/create-sample-request')}
        >
          Create New Sample Request
        </Button>
      </ManageUiByRole>
    ),
    'User List': (
      <ManageUiByRole feature="user" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => history.push('/create-user')}
        >
          Create New User
        </Button>
      </ManageUiByRole>
    ),
    Roles: (
      <ManageUiByRole feature="role" action="create">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          {...btnProps}
          onClick={() => setOpenCreateRoleDialog(true)}
        >
          Create New Role
        </Button>
      </ManageUiByRole>
    ),
  };

  return (
    <>
      {titlesAndBtns[title] || null}
      <CreateRoleForm
        open={openCreateRoleDialog}
        setOpen={setOpenCreateRoleDialog}
        handleClose={handleCloseCreateRoleDialog}
      />
    </>
  );
};

export default TitlesAndBtns;
