import axios from 'axios';

import localStorageHandler from '../../../utilities/handleLocalStorage';

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const fetchAndUpdateLocalStorage = async () => {
  try {
    const {
      data: { currentUserInfo },
    } = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}api/v1/users/get-me`
    );
    const {
      _id: userId,
      phone,
      roleName,
      email,
      avatar = [],
      firstName,
      lastName,
    } = currentUserInfo;
    const userName = capitalize(firstName) + ' ' + capitalize(lastName);
    const updatedLocalStorageData = {
      userId,
      phone,
      roleName,
      email,
      userName,
    };
    if (avatar.length) {
      updatedLocalStorageData.userProfile =
        avatar[avatar.length - 1].key_thumbnailImg;
    }
    localStorageHandler.setUserInfo(updatedLocalStorageData);
  } catch (error) {
    console.log(error);
  }
};

export default fetchAndUpdateLocalStorage;
