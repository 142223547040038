import { createContext, useState, useEffect } from 'react';
import fetchAndCreatePermissionObj from './fetch-and-set-permission';
import fetchAndUpdateLocalStorage from './fetch-and-update-local-storage';

export const PermissionContext = createContext({
  permission: {},
  setPermission: () => {},
});

const PermissionContextProvider = ({ children }) => {
  const [permission, setPermission] = useState({});
  // When First Mount fetch all roles and its permission
  // Also fetch the current user role to generate permission accordingly
  useEffect(() => {
    const getCurrentUserInfoAndPermission = async () => {
      if (localStorage.getItem('userInfo')) {
        setPermission(await fetchAndCreatePermissionObj());

        await fetchAndUpdateLocalStorage();
      }
    };
    getCurrentUserInfoAndPermission();
  }, []);

  return (
    <PermissionContext.Provider value={{ permission, setPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContextProvider;
