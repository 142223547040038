import React, { useState } from "react";

export const LoadingContext = React.createContext({
  isLoading: false,
  setIsLoading: () => {},
  isLoadingWithoutLayout: false,
  setIsLoadingWithoutLayout: () => {},
});

const LoadingContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWithoutLayout, setIsLoadingWithoutLayout] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isLoadingWithoutLayout,
        setIsLoadingWithoutLayout,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
