import axios from 'axios';

const fetchAndCreatePermissionObj = async () => {
  // Obj to setState after converting data
  const currentUserPermission = {};
  // Fetch role and permission and the current user's role
  const { data } = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}api/v1/role/find-role-by-userID`,
    {_id:''},
    {
      withCredentials: true,
    }
  );
  const { features } = data;
  // Converting the Feature to be an Obj ( Easy for checking )
  await Promise.all(
    features.map(async (feature) => {
      const { featureName, rolePermission } = feature;
      const helperObj = {};
      await Promise.all(
        rolePermission.map((item) => {
          const { permissionName, boolean } = item;
          helperObj[permissionName] = boolean;
          return null
        })
      );
      currentUserPermission[featureName] = helperObj;
    })
  );
  return currentUserPermission;
};

export default fetchAndCreatePermissionObj;
