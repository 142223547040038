import firebase from 'firebase/app';
import 'firebase/database';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC3FWRQWzVmehF5jDCF3nmPI3_sIN88dGE',
  authDomain: 'drug24-310203.firebaseapp.com',
  projectId: 'drug24-310203',
  storageBucket: 'drug24-310203.appspot.com',
  messagingSenderId: '1068400343980',
  appId: '1:1068400343980:web:ef5ebac3656c645e729809',
  measurementId: 'G-00S7STGXYT',
};

firebase.initializeApp( firebaseConfig );