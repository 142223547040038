/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useEffect, useState, lazy } from 'react';
import axios from 'axios';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
// Utils
import Spinner from './utilities/feedback-user/spinner';
import PrivateRoute from './hoc/privateRoute';
import RouteX from './hoc/routeX';
import Layout from './hoc/layout';
import { LoadingContext } from './hoc/context/loading-context';
import { ErrorContext } from './hoc/context/error-context';
import NonActionSnackBar from './utilities/feedback-user/nonActionSnackBar';

// Authentication
const Login = lazy(() => import('./components/authentication/login'));
const Logout = lazy(() => import('./components/authentication/logout'));
const ResetPassword = lazy(() =>
  import('./components/authentication/reset-password-form/')
);
const ForgotPassword = lazy(() =>
  import('./components/authentication/forgot-password-form')
);
// Dashboard or Home
const Dashboard = lazy(() => import('./components/dashboard'));
// Product
const CreateProduct = lazy(() => import('./components/product/createProduct'));
const ProductList = lazy(() => import('./components/product/productList'));
const ExpiringProducts = lazy(() =>
  import('./components/product/expiring-products')
);
// Order
const OrderList = lazy(() => import('./components/order/orderList'));
const CreateOrder = lazy(() => import('./components/order/createOrder'));
// User
const UsersList = lazy(() => import('./components/user/users-list'));
const CreateUser = lazy(() => import('./components/user/create-user'));
const EditUpdateUser = lazy(() => import('./components/user/edit-update-user'));
const UserProfile = lazy(() => import('./components/user/user-profile'));
// Customer
const CreateCustomer = lazy(() =>
  import('./components/customer/createCustomer')
);
const CustomerList = lazy(() => import('./components/customer/customerList'));
// Supplier
const SupplierList = lazy(() => import('./components/suppliers/suppiler-list'));
const CreateSupplier = lazy(() =>
  import('./components/suppliers/create-supplier')
);
// categories
const CreateCategory = lazy(() =>
  import('./components/category/create-category-form')
);
const CategoryList = lazy(() => import('./components/category'));
// Report
// const SalesSummary = lazy(() => import('./components/reports/sales-summary'));
const SalesList = lazy(() => import('./components/reports/sales-list'));
const SalesByItem = lazy(() => import('./components/reports/sales-by-item'));
const SalesByUser = lazy(() => import('./components/reports/sales-by-user'));
const SalesByCustomer = lazy(() =>
  import('./components/reports/sales-by-customer')
);
// const SalesByCategory = lazy(() => import('./components/reports/sales-by-category'));
const ProductNotFound = lazy(() =>
  import('./components/reports/product-not-found')
);
// Sample Request
const CreateSampleRequest = lazy(() =>
  import('./components/sample-request/create-sample-request')
);
const SampleRequestList = lazy(() =>
  import('./components/sample-request/sample-request-list')
);
// Settings
const GeneralSettingPage = lazy(() => import('./pages/settings/general'));
const UserRolePermission = lazy(() =>
  import('./pages/settings/role/user-role-permission')
);
const ExchangeRate = lazy(() => import('./pages/settings/exchange-rate'));
const UnitList = lazy(() => import('./pages/settings/unit'));

const App = () => {
  // For Error Handling
  const loadingContext = useContext(LoadingContext);
  const errorContext = useContext(ErrorContext);
  const [redirect, setRedirect] = useState(null);
  useEffect(() => {
    // Handle before request is sent
    // Use for Start Loading Spinner
    axios.interceptors.request.use(
      (req) => {
        loadingContext.setIsLoading(true);
        return req;
      },
      (err) => {
        loadingContext.setIsLoading(false);
        throw err;
      }
    );
    // Handle before response is recieved
    // Use for Stop Loading Spinner And Handle Error Case
    axios.interceptors.response.use(
      (res) => {
        loadingContext.setIsLoading(false);
        return res;
      },
      (err) => {
        errorContext.setMessage('');
        // Check Status and React Accordingly
        switch (err.response?.status) {
          case 400:
            errorContext.setMessage(
              err.response.data.message
                ? err.response.data.message
                : 'Client Errors Occur !'
            );
            break;
          case 401:
            errorContext.setMessage(err.response.data.message);
            setRedirect(<Redirect to="/logout" />);
            break;
          case 403:
            errorContext.setMessage(
              err.response.data.message
                ? err.response.data.message
                : 'Request is Forbidden Due to Permissions'
            );
            localStorage.removeItem('userInfo');
            setRedirect(<Redirect to="/login" />);
            break;
          case 500:
            errorContext.setMessage(
              err.response.data.message
                ? err.response.data.message
                : 'Either Server or Database Error Occurs !'
            );
            if (err.response.data.url) {
              setRedirect(<Redirect to={err.response.data.url} />);
            }
            break;
          default:
            break;
        }
        setRedirect(null);
        errorContext.setShow(true);
        loadingContext.setIsLoading(false);
        throw err;
      }
    );
  }, []);

  let routes = (
    <Switch>
      {/* Component without Layout */}
      {loadingContext.isLoadingWithoutLayout && <Spinner />}
      <RouteX path="/login" exact component={Login} redirect="/dashboard" />
      <RouteX
        path="/forgot-password"
        exact
        component={ForgotPassword}
        redirect="/dashboard"
      />
      <RouteX
        path="/reset-password"
        exact
        component={ResetPassword}
        redirect="/dashboard"
      />

      {/* Component with Layout */}
      <Route>
        <Layout>
          {loadingContext.isLoading && <Spinner />}
          <NonActionSnackBar
            autoHideDuration={null}
            show={errorContext.show}
            message={errorContext.message}
          />
          {redirect}
          <Suspense fallback={<Spinner />}>
            <Switch>
              <PrivateRoute path="/dashboard" exact component={Dashboard} />
              <PrivateRoute path="/user-list" exact component={UsersList} />
              <PrivateRoute path="/profile" exact component={UserProfile} />
              <PrivateRoute
                path="/create-product/:id?"
                exact
                component={CreateProduct}
              />
              <PrivateRoute
                path="/product-list"
                exact
                component={ProductList}
              />
              <PrivateRoute
                path="/expiring-products"
                exact
                component={ExpiringProducts}
              />
              <PrivateRoute path="/logout" exact component={Logout} />
              <PrivateRoute path="/create-user" exact component={CreateUser} />
              <PrivateRoute
                path="/edit-user/:id"
                exact
                component={EditUpdateUser}
              />
              <PrivateRoute
                path="/roles"
                exact
                component={UserRolePermission}
              />
              <PrivateRoute path="/order-list" exact component={OrderList} />
              <PrivateRoute
                path="/create-order/:id?"
                exact
                component={CreateOrder}
              />
              <PrivateRoute
                path="/customer-list"
                exact
                component={CustomerList}
              />
              <PrivateRoute
                path="/create-customer/:id?"
                exact
                component={CreateCustomer}
              />
              <PrivateRoute
                path="/category-list"
                exact
                component={CategoryList}
              />
              <PrivateRoute
                path="/create-category/:id?"
                exact
                component={CreateCategory}
              />
              <PrivateRoute
                path="/create-supplier/:id?"
                exact
                component={CreateSupplier}
              />
              {/* <PrivateRoute path='/sales-summary' exact component={SalesSummary} /> */}
              <PrivateRoute
                path="/supplier-list"
                exact
                component={SupplierList}
              />
              <PrivateRoute path="/unit-list" exact component={UnitList} />
              <PrivateRoute
                path="/sales-by-order"
                exact
                component={SalesList}
              />
              <PrivateRoute
                path="/sales-by-item"
                exact
                component={SalesByItem}
              />
              <PrivateRoute
                path="/sales-by-user"
                exact
                component={SalesByUser}
              />
              <PrivateRoute
                path="/sales-by-customer"
                exact
                component={SalesByCustomer}
              />
              {/* <PrivateRoute path='/sales-by-category' exact component={SalesByCategory} /> */}
              <PrivateRoute
                path="/product-not-found"
                exact
                component={ProductNotFound}
              />
              <PrivateRoute
                path="/general-setting"
                exact
                component={GeneralSettingPage}
              />
              <PrivateRoute
                path="/exchange-rate"
                exact
                component={ExchangeRate}
              />
              <PrivateRoute
                path="/create-sample-request"
                exact
                component={CreateSampleRequest}
              />
              <PrivateRoute
                path="/sample-request/:mode/:sampleRequestId"
                exact
                component={CreateSampleRequest}
              />
              <PrivateRoute
                path="/sample-request-list"
                exact
                component={SampleRequestList}
              />
              <Route
                path="/"
                render={() =>
                  localStorage.getItem('userInfo') ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
            </Switch>
          </Suspense>
        </Layout>
      </Route>
    </Switch>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        {routes}
        {errorContext.show && (
          <NonActionSnackBar
            autoHideDuration={null}
            severity="error"
            show={errorContext.show}
            message={errorContext.message}
          />
        )}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
