import React, { useState, useEffect } from 'react';
import { Paper, Box, makeStyles, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { subString } from '../../utilities/elipsis-text';
import { useHistory } from 'react-router';

const useStyle = makeStyles(theme => ({
  root: {
    width: '370px',
    padding: '0.6rem 0.6rem 0.6rem 0.6rem',
  },
  singleReport: {
    marginBottom: '0.6rem',
    padding: '0.6rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
    },
  },
}));

const LowStockProducts = ({ fetchdata, count, setCount, setClose }) => {
  const classes = useStyle();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await fetchdata(page, perPage);
        setData(prev => [...prev, ...data.data]);
        setCount(data.totalItems);
      } catch (error) {
        console.log(error.response);
      }
    };
    fetch();
  }, [page]);

  const results = (
    <>
      {data.map(item => {
        const { _id, barcode, name, qty } = item;
        const productName = subString(name, 55);
        return (
          <Box
            id={_id}
            className={classes.singleReport}
            onClick={async () => {
              await setClose(null);
              history.push({ pathname: '/product-list', search: `search=${barcode}&${Math.random()}` });
            }}
          >
            <Typography>
              <strong>({barcode}) </strong>
              <strong>{productName}</strong> has only{` `}
              <strong style={{ color: '#E02241' }}>{qty}</strong> left.
            </Typography>
          </Box>
        );
      })}
    </>
  );

  return (
    <Paper elevation={1} className={classes.root}>
      <Typography
        variant='h6'
        style={{
          fontWeight: 'bold',
          color: 'rgb(49, 49, 49)',
          padding: '0.2rem 0.4rem',
          marginBottom: '0.5rem',
        }}
      >
        Low Stock Products
      </Typography>
      <div id='scrollableDiv' style={{ maxHeight: '80vh', overflow: 'auto' }}>
        <InfiniteScroll
          dataLength={data.length} //This is important field to render the next data
          next={() => setPage(prev => prev + 1)}
          hasMore={data.length === count ? false : true}
          loader={
            <Typography align='center' style={{ color: '#6b6b6b' }}>
              loading...
            </Typography>
          }
          scrollableTarget='scrollableDiv'
          endMessage={
            data.length <= 0 && (
              <Typography align='center' style={{ color: '#6b6b6b' }}>
                No result
              </Typography>
            )
          }
        >
          {results}
        </InfiniteScroll>
      </div>
    </Paper>
  );
};

export default LowStockProducts;
