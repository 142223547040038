import React from "react";
import { Redirect, Route } from "react-router-dom";
import routesAndPermissions from "../../utilities/dataForConfiguration/routes-and-permissions";
import ManageUIbyRole from "../../hoc/manage-ui-by-role";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const route = props.match.path.split("/")[1];
        const conditions = routesAndPermissions[`/${route}`];
        return localStorage.getItem("userInfo") ? (
          <ManageUIbyRole conditions={conditions}>
            <Component {...props} />
          </ManageUIbyRole>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />

  )
}

export default PrivateRoute;
