import React, { useEffect, useState } from 'react';
import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import localStorageHandler from '../../utilities/handleLocalStorage';
import DashboardAppBar from './appBar';
import Drawer from './drawer';
import breadcrumbsInfo from '../../utilities/dataForConfiguration/breadcrumbsDatas';
import Breadcrumb from '../../utilities/breadcrumbs';

const useStyles = makeStyles((theme, open) => ({
  content: {
    flexGrow: 1,
    padding: 0,
    minHeight: '100vh',
    overflowX: 'auto',
    [theme.breakpoints.only('xs')]: { paddingLeft: '72px' },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  viewContent: {
    overflowX: 'auto',
    [theme.breakpoints.down('lg')]: {
      minWidth: '1280px',
    },
  },
}));

const Layout = ({ children }) => {
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    title: '',
    parents: [],
  });
  const [userInfo, setUserInfo] = useState({
    username: '',
    email: '',
    userProfile: '',
    roleName: '',
  });

  const location = useLocation();

  useEffect(() => {
    const pathnames = window.location.pathname.split('/');
    const pathname = pathnames[pathnames.length - 1];
    setBreadcrumbsData(breadcrumbsInfo['/' + pathname]);
    setUserInfo(localStorageHandler.getUserInfo());
  }, [location]);

  // Extract role from userInfo
  const role = userInfo?.roleName;

  const [open, setOpen] = useState(true);
  const classes = useStyles({ open });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <DashboardAppBar
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        userInfo={userInfo}
      />
      <Drawer open={open} role={role} />
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <div className={classes.viewContent}>
          {window.location.pathname === '/dashboard' ? (
            ''
          ) : (
            <Breadcrumb data={breadcrumbsData || { title: '', parents: [] }} />
          )}
          <div style={{ flexGrow: 1 }}>{children}</div>
        </div>
      </main>
    </div>
  );
};

export default React.memo(Layout);
