import { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import moment from 'moment';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  withCredentials: true,
});

const useStyles = makeStyles((theme) => ({
  noficationElement: {
    padding: '0.6rem',
    paddingRight: '0.3rem',
    backgroundColor: 'white',
    marginBottom: '0px',
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(245, 245, 245)',
    },
  },
  notificationMessage: ({ frontendIsRead }) => ({
    display: '-webkit-box',
    maxHeight: '5rem',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: frontendIsRead ? '#65676b' : '#050505',
  }),
  haveNotReadBadge: {
    backgroundColor: '#599DE8',
    borderRadius: '50%',
    width: '13px',
    height: '13px',
    '&:hover': {
      opacity: '0.8',
    },
  },
  haveReadBadge: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    width: '15px',
    height: '15px',
    border: '1px solid #599DE8',
    '&:hover': {
      backgroundColor: 'rgb(238, 238, 238)',
    },
  },
}));

const SingleNotification = ({
  customerName,
  workerName,
  invoiceNumber,
  action,
  orderId,
  isRead,
  isSeen,
  status,
  index,
  last,
  id,
  setShowNotification,
  frontendIsReadFromParent,
  setPage,
  updateUnseenNotification,
  date,
  createdFrom,
}) => {
  // Handle frontend notification status ( For change style and make request to change in the background )
  const [frontendIsRead, setFrontendIsRead] = useState(false);
  // For Navigation
  const history = useHistory();
  // MUI Classes
  const classes = useStyles({ frontendIsRead });
  // Check if the user see the notification or not
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  useEffect(async () => {
    // If we reach last notification fetch more
    if (inView && last) {
      setPage((oldPage) => oldPage + 1);
    }
  }, [inView]);

  useEffect(() => {
    setFrontendIsRead(isRead);
  }, []);

  useEffect(() => {
    if (frontendIsReadFromParent?.isRead) {
      setFrontendIsRead(true);
    }
  }, [frontendIsReadFromParent]);

  // When user click on notification to read more => change isRead to true
  const readNotification = async ({ id, navigation, e }) => {
    // Prevent triggering onClick in Parent element when clicking mark as read btn
    e?.stopPropagation();
    // Update when user has read notification
    const { data } = await axiosInstance.post('api/v1/notification/update', {
      _id: id,
      action: 'readNotification',
    });
    // Update View
    // Change frontend style without fetching notification and set new state
    setFrontendIsRead(true);
    // Check if the user mark only a notification to already read => don't navigate
    if (navigation) {
      history.push(`/order-list?${Math.random()}id=${orderId}`);
      setShowNotification(false);
    }
  };

  // Handle When user Click Mark as Unread
  const markAsUnReadHandler = async (e) => {
    // Prevent triggering onClick in Parent element when clicking mark as read btn
    e.stopPropagation();

    try {
      // Update when user has unread notification
      const { data } = await axiosInstance.post('api/v1/notification/update', {
        _id: id,
        action: 'unreadNotification',
      });
      // Change frontend style without fetching notification and set new state
      setFrontendIsRead(false);
    } catch (err) {
      console.log(err);
    }
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  // Creating Notification's Message here
  const message = (
    <>
      <Typography
        component="div"
        variant="body2"
        className={classes.notificationMessage}
      >
        <strong>{capitalize(workerName)}</strong>
        <span>&nbsp;{action}</span>
        <strong>&nbsp;{invoiceNumber}</strong>
        {createdFrom === 'frontend' ? (
          <>
            <span>&nbsp;from</span>
            <strong>&nbsp;Website</strong>
          </>
        ) : (
          <>
            <span>&nbsp;for</span>
            <strong>&nbsp;{capitalize(customerName)}</strong>
          </>
        )}
      </Typography>
      <Typography style={{ color: '#808080' }} variant="body2">
        {moment(date).format('DD-MM-YYYY')}&nbsp;&#8226;&nbsp;
        {moment(date).format('h:mm A')}
      </Typography>
    </>
  );

  // Render View
  return (
    <Grid
      container
      className={classes.noficationElement}
      key={index}
      ref={ref}
      id={`notification_${index}`}
      onClick={(e) => {
        readNotification({ id, navigation: true, e });
      }}
    >
      <Grid item xs={true}>
        {message}
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{ width: '13px', margin: '0 0.5rem' }}
      >
        {frontendIsRead ? (
          <div
            className={classes.haveReadBadge}
            onClick={(e) => markAsUnReadHandler(e)}
          ></div>
        ) : (
          <div
            className={classes.haveNotReadBadge}
            onClick={(e) => readNotification({ e, id, navigation: false })}
          ></div>
        )}
      </Grid>
    </Grid>
  );
};

export default SingleNotification;
