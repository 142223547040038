import firebase from 'firebase';
import './config';

const database = firebase.database();

const listenAndTriggerCallback = async ({ userId, callback }) => {
  // Listen to data added to a path in firebase real time database
  await database
  // Path in DB
  .ref(`${userId}/notifications`)
  .on("child_added", async(snapshot) => {
    const newNotification = snapshot.val();
    if (newNotification.data) {
      // Do action when data added to path
      await callback({newNotification:newNotification.data});
    }
  });
}

export { listenAndTriggerCallback }