/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Badge,
  Avatar,
  withStyles,
  Button,
  Popover,
  Collapse,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useHistory } from 'react-router-dom';
import AllNotifications from './all-notification';
import LowStockProducts from '../../../components/low-stock-products';
//icon
import ProductsSvg from '../../../assets/drawerIcon/box-open-solid.svg';
import handleLocalStorage from '../../../utilities/handleLocalStorage';
// Firebase
import { listenAndTriggerCallback } from '../../../utilities/firebase';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  withCredentials: true,
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#2a55d8',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 'calc(100% - 64px)',
    [theme.breakpoints.only('xs')]: {
      position: 'fixed',
      width: '100%',
    },
  },
  appBarShift: {
    [theme.breakpoints.only('xs')]: {
      position: 'fixed',
      width: '100%',
    },
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
    },
  },
  hide: {
    display: 'none',
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  notificationBadge: {
    cursor: 'pointer',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 1,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '2px 4px',
    // padding: '2px',
    marginLeft: '5rem',
  },
}))(Badge);

const StyledBadgeLowStock = withStyles((theme) => ({
  badge: {
    right: -3,
    top: -3,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '2px 4px',
    marginLeft: '5rem',
  },
}))(Badge);

const DashboardAppBar = ({
  handleDrawerClose,
  handleDrawerOpen,
  open,
  userInfo,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { userName, userProfile } = userInfo;
  // Handle amount of unseen notification
  const [unseenNotification, setUnseenNotification] = useState(0);
  // Handle if to show notification'
  const [showNotification, setShowNotification] = useState(false);
  // Handle Notification Node
  const node = useRef();
  // Handle Notification Data
  const [notification, setNotification] = useState([]);
  // Handle limit and page for infinite scrolling
  const limit = 10;
  const [page, setPage] = useState(1);

  //achor for low stock popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [countLowStockPoducts, setCountLowStockProducts] = useState(0);
  //query for low stock product
  const [lowStockQuery, setLowStockQuery] = useState({
    page: 1,
    perPage: 10,
  });

  // When cmp first mounted
  useEffect(() => {
    // Add when mounted
    document.addEventListener('mousedown', handleClick);
    // Remove EventListener when Cmp unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  //fetch low stock products
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await fetchLowStockProduct(
          lowStockQuery.page,
          lowStockQuery.perPage
        );
        setCountLowStockProducts(data.totalItems);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  // If page change then get next page
  useEffect(() => {
    // Get Amount of Unseen Notification
    updateUnseenNotification();
    // Fetch Notification
    fetchNotification();
  }, [page]);

  // Fetch notification data => Triggers Update View ( number on badge and notification data )
  const fetchNotification = async () => {
    // Get the lastest version of notification
    const {
      data: { notification = [] },
    } = await axiosInstance.post('api/v1/notification', {
      skip: (page - 1) * limit,
      limit,
    });
    // Set state to make AllNotification generating the element
    setNotification((oldNotification) => {
      return [...oldNotification, ...notification];
    });
  };

  // Update unseen notification
  const updateUnseenNotification = async () => {
    // Get Amount of Unseen Notification
    const { data: unseenData } = await axiosInstance.get(
      'api/v1/notification/amount-unseen-notification'
    ); // Set state to update number of unseen notification on bell badge
    setUnseenNotification(unseenData?.amountOfUnSeenNotification);
  };

  const fetchLowStockProduct = async (page, perPage) => {
    const {
      data: { data, totalItems },
    } = await axiosInstance.post(
      `api/v1/products/alertQty?page=${page}&perPage=${perPage}`
    );
    return {
      data,
      totalItems,
    };
  };

  // Check if user click anywhere outside notification => close notification
  const handleClick = (e) => {
    if (node?.current?.contains(e?.target)) {
      return;
    }
    setShowNotification(false);
  };
  const handleClickLowStock = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseLowStock = () => {
    setAnchorEl(null);
  };
  const openLowStock = Boolean(anchorEl);

  // ** Firebase Section **
  const callback = useCallback(async ({ newNotification }) => {
    // Change the number of the bell's badge
    setUnseenNotification((prev) => prev + 1);
    // Change the notifications
    setNotification((prevNotifications) => [
      JSON.parse(newNotification),
      ...prevNotifications,
    ]);
  }, []);
  useEffect(() => {
    const { userId = '' } = handleLocalStorage.getUserInfo() || { userId: '' };
    listenAndTriggerCallback({ userId, callback });
  }, [callback]);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        {open ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <ChevronLeftIcon style={{ fontSize: '1.8rem' }} />
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <ChevronRightIcon style={{ fontSize: '1.8rem' }} />
          </IconButton>
        )}
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
        >
          <Grid
            item
            style={{
              marginTop: '0.45rem',
              paddingRight: '2rem',
              position: 'relative',
            }}
          >
            {/* low stock product */}
            <StyledBadgeLowStock
              color="secondary"
              badgeContent={countLowStockPoducts}
              onClick={handleClickLowStock}
              style={{ cursor: 'pointer' }}
            >
              <img src={ProductsSvg} alt="Icon" width="23px" height="auto" />
            </StyledBadgeLowStock>
            <Popover
              style={{ marginTop: '1.2rem' }}
              open={openLowStock}
              anchorEl={anchorEl}
              onClose={handleCloseLowStock}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              TransitionComponent={Collapse}
            >
              <LowStockProducts
                fetchdata={fetchLowStockProduct}
                count={countLowStockPoducts}
                setCount={setCountLowStockProducts}
                setClose={setAnchorEl}
              />
            </Popover>
          </Grid>
          <Grid
            item
            style={{
              marginTop: '7px',
              marginRight: '3rem',
              position: 'relative',
            }}
            ref={node}
          >
            <StyledBadge
              color="secondary"
              badgeContent={unseenNotification}
              className={classes.notificationBadge}
              onClick={async () => {
                // Toggle Show Notification
                setShowNotification(!showNotification);
                // Make Request to update the all nofication 's isSeen to true and update view
                const { data: updatedNotification } = await axiosInstance.post(
                  'api/v1/notification/update',
                  {
                    action: 'seenNotification',
                  }
                );
                updateUnseenNotification();
              }}
            >
              <NotificationsIcon style={{ fontSize: '1.8rem' }} />
            </StyledBadge>
            <AllNotifications
              notification={notification}
              showNotification={showNotification}
              setShowNotification={setShowNotification}
              fetchNotification={fetchNotification}
              setPage={setPage}
              updateUnseenNotification={updateUnseenNotification}
            />
          </Grid>
          <Grid item style={{ marginRight: '1.3rem' }}>
            <Avatar
              alt="User"
              src={
                userProfile && process.env.REACT_APP_AWS_BUCKET + userProfile
              }
              className={classes.avatar}
            />
          </Grid>
          <Grid item style={{ marginTop: '5px' }}>
            <Typography style={{ fontSize: '1.2rem', minWidth: '6rem' }}>
              {userName}
            </Typography>
          </Grid>
          {userInfo && (
            <Grid item style={{ marginLeft: '2rem' }}>
              <Button
                onClick={() => {
                  history.replace('/logout');
                }}
                style={{ color: 'white' }}
              >
                Logout
              </Button>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardAppBar;
