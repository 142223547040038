const keyName = "userInfo";

const isAuthenticated = () => {
  let authenticated;
  authenticated = localStorage.getItem(keyName) ? true : false;
  window.addEventListener("storage", () => {
    authenticated = localStorage.getItem(keyName) ? true : false;
  });
  return authenticated;
};

const getUserInfo = () => {
  let data;
  data = JSON.parse(localStorage.getItem(keyName));
  window.addEventListener("storage", () => {
    data = JSON.parse(localStorage.getItem(keyName));
  });
  return data;
};

const setUserInfo = (data) => {
  localStorage.setItem(keyName, JSON.stringify(data));
};

const functions = { isAuthenticated, getUserInfo, setUserInfo };

export default functions;
