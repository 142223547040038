/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useContext, useEffect, useState, memo } from 'react';
import { PermissionContext } from '../../hoc/context/permission-context';
import { Box } from '@material-ui/core';

const ManageUIbyRole = (props) => {
  const [elementToReturn, setElementToReturn] = useState(null);
  const { permission } = useContext(PermissionContext);
  const { feature, action, children, conditions, onClick } = props;

  useEffect(() => {
    const checkPermissionAndReturnCmp = async () => {
      if (Object.keys(permission).length) {
        if (conditions) {
          const { features, andOr, dontCheck } = conditions;
          if (dontCheck) {
            return setElementToReturn(children);
          }
          let valid = andOr === '||' ? false : true;
          const shows = await Promise.all(
            features.map((item) => {
              const {
                andOr,
                permission: permissionFromJSON,
                featureName,
              } = item;
              let valid = andOr === '||' ? false : true;
              for (const key in permissionFromJSON) {
                if (permission[featureName][key] === permissionFromJSON[key]) {
                  valid = andOr === '||' ? valid || true : valid && true;
                } else {
                  valid = andOr === '||' ? valid || false : valid && false;
                }
              }
              return valid;
            })
          );
          shows.forEach((item) => {
            valid = andOr === '||' ? valid || item : valid && item;
          });
          setElementToReturn(valid ? children : null);
        } else {
          setElementToReturn(
            permission[feature][action] ? (
              <Box onClick={onClick || children?.props?.onClick} clone>
                {children}
              </Box>
            ) : null
          );
        }
      }
    };
    checkPermissionAndReturnCmp();
  }, [permission, children]);

  return elementToReturn;
};

export default memo(ManageUIbyRole);
