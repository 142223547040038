import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import LoadingContextProvider from './hoc/context/loading-context';
import ErrorContextProvider from './hoc/context/error-context';
import PermissionContextProvider from './hoc/context/permission-context';
import './index.css';
import App from './App';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import theme from './hoc/theme';

axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_API}`;
axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <PermissionContextProvider>
      <ErrorContextProvider>
        <LoadingContextProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              autoHideDuration={4000}
              maxSnack={4}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </LoadingContextProvider>
      </ErrorContextProvider>
    </PermissionContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
