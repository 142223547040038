import React, { useState } from 'react';

import roleAndMenu from '../../../../utilities/dataForConfiguration/roleAndMenu';
import menuAndDropdown from '../../../../utilities/dataForConfiguration/menuAndDropdown';
import MenuItem from './menuItem';

// Menu Svgs
import DashboardSvg from '../../../../assets/drawerIcon/tachometer-alt-solid.svg';
import ProductsSvg from '../../../../assets/drawerIcon/box-open-solid.svg';
import CategorySvg from '../../../../assets/drawerIcon/category.svg';
import OrdersSvg from '../../../../assets/drawerIcon/shopping-cart-solid.svg';
import CustomersSvg from '../../../../assets/drawerIcon/users-solid.svg';
import SuppliersSvg from '../../../../assets/drawerIcon/industry-solid.svg';
import ReportsSvg from '../../../../assets/drawerIcon/chart-bar-solid.svg';
import UserSvg from '../../../../assets/drawerIcon/user-solid.svg';
import SettingSvg from '../../../../assets/drawerIcon/cogs-solid.svg';
import FormSvg from '../../../../assets/drawerIcon/wpforms-brands.svg';

const menuAndItsSvg = {
  DASHBOARD: DashboardSvg,
  CATEGORY: CategorySvg,
  PRODUCTS: ProductsSvg,
  ORDERS: OrdersSvg,
  CUSTOMERS: CustomersSvg,
  SUPPLIERS: SuppliersSvg,
  REPORTS: ReportsSvg,
  'SAMPLE-REQUEST': FormSvg,
  USER: UserSvg,
  SETTING: SettingSvg,
};

const GenerateMenuItems = ({ open }) => {
  // Active Index
  const [active, setActive] = useState(0);
  // Create Menu from roleAndMenu json file in utilities
  const menus = roleAndMenu.role_and_menus.map((item, index) => {
    const { title, href, dropdown, marginBottom, conditions } =
      menuAndDropdown[item];

    return (
      <MenuItem
        conditions={conditions}
        title={title}
        key={index}
        href={href}
        icon={menuAndItsSvg[item]}
        dropdown={dropdown}
        marginBottom={marginBottom}
        active={index === active}
        setActive={() => setActive(index)}
        index={index}
        open={open}
      />
    );
  });
  return <>{menus}</>;
};

export default GenerateMenuItems;
